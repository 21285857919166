import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';
import MobileDrawer from './MobileDrawer.component';
import HiddenOn from '../common/HiddenOn.component';
import DesktopMenu from './desktopMenu/DesktopMenu.component';
import MobileMenu from './mobileMenu/MobileMenu.component';
import Link from '../common/Link.component';
import Logo from '../common/logo.component';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import LoginSection from './LoginSection.component';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import { filterLinks } from '../../utils/utils';

const drawerWidth = 20;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: theme.palette.grayScale.g0,
    color: theme.palette.secondary.main
  },
  appBarShift: {
    width: 0,
    marginLeft: `${drawerWidth}rem`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  zIndexEnhance:{
    zIndex: 9999
  },
  menuButton: {
    marginRight: theme.spacing(0),
  },
  userButton:{
    marginLeft: theme.spacing(0),
  },
  hide: {
    display: 'none',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 0.5rem',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  grow: {
    margin: 'auto',
    [theme.breakpoints.up('md')] : {
      flexGrow: 1,
    },
  },
  logo: {
    height: '2.5rem',
    width: '7rem',
    display: 'flex',
    [theme.breakpoints.up('lg')] : {
      flexGrow: 1,
      width: '11rem',
      height: '3.75rem',
    },
  },
  toolbar: {
    [theme.breakpoints.down('sm')] : {
      borderBottom: `1px solid ${theme.palette.grayScale.g200}`,
      padding: `0 ${theme.space.horizontal.mobile}`
    },
    [theme.breakpoints.up('md')] : {
      padding: `0 ${theme.space.horizontal.desktop}`
    },
  },
  welcome: {
    display: 'block',
    fontSize: theme.typography.size.smaller,
    textAlign: 'end',
  },
  name: {
    fontSize: theme.typography.size.smaller,
  },
  hiddenLogin: {
    [theme.breakpoints.down('sm')] : {
      '& .MuiButtonBase-root': {
        marginRight: 0
      },
      '& > .MuiGrid-root': {
        margin: 0,
        marginLeft: 'calc(50% - 2.25rem)',
        transform: 'translate(-50%, 0)'
      }
    }
  },
  hiddenMenuButton: {
    [theme.breakpoints.down('sm')] : {
      '& > .MuiGrid-root': {
        marginLeft: '50%',
      }
    }
  },
  backButton: {
    color: theme.palette.primary.main2
  },
  blackButton: {
    color: theme.palette.grayScale.g400,
    fontSize: '14px',
    marginLeft: '10px'
  }
}));

const Header = ({ 
  menuLinks, 
  isLoggedIn = false,
  profile, 
  isAdmin, 
  showMobileBackIcon,
  showMenuButton = true,
  styleButtonBlack = false, 
  onGoBack = () => {}, 
  loginText,
  hideMobileUserMenu
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openUserMenu, setOpenUserMenu] = React.useState(false);
  
  const { role_name } = profile || {}

  const filteredMenuLinks = filterLinks(menuLinks, isLoggedIn, role_name)
  function handleDrawerOpen() {
    setOpen(!open);
    setOpenUserMenu(false)
  }

  function handleDrawerOpenUserMenu() {
    setOpenUserMenu(!openUserMenu);
    setOpen(false)
  }

  function handleDrawerClose() {
    setOpen(false);
  }

  const toggleDrawerOpenUserMenu = (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpenUserMenu(false);
  };

  function renderizerIconToolbar() {
    if(!showMenuButton) return null;

    if (open) {
      return(
        <IconButton
        color="inherit"
        aria-label="Open drawer"
        onClick={handleDrawerOpen}
        edge="start"
        className={clsx(classes.menuButton)}
      >
        <CloseIcon />
      </IconButton>
      ) 
    } else {
      if (showMobileBackIcon) {
        return (
          <IconButton
            color="inherit"
            edge="start"
            onClick={onGoBack} 
          >
            <ArrowBackIosIcon className={clsx(classes.backButton, {[classes.blackButton]: styleButtonBlack})} />
          </IconButton>
        )
      } else {
        return (
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton)}
          >
            <MenuIcon />
          </IconButton>
        )
      }
    }
  }

  const renderizerUserMobilMenu = () => {
    if (openUserMenu) {
      return (
      <IconButton
        color="inherit"
        aria-label="Open drawer"
        onClick={handleDrawerOpenUserMenu}
        edge="start"
        className={clsx(classes.menuButton)}
      >
        <CloseIcon/>
      </IconButton>
    )} else 
     {
      return (
      <IconButton
        color="inherit"
        aria-label="Open drawer"
        onClick={handleDrawerOpenUserMenu}
        edge="start"
        className={clsx(classes.menuButton)}
      >
        <AccountCircleOutlinedIcon />
      </IconButton>
    )} 
  }

  let toolbarClass = `${classes.toolbar}`;
  if(!isLoggedIn) toolbarClass += ` ${classes.hiddenLogin}`;
  if(!showMenuButton) toolbarClass += ` ${classes.hiddenMenuButton}`;

  return (
    <Grid className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        elevation={0}
        className={clsx(classes.appBar, { [classes.zIndexEnhance]: open || openUserMenu })}
      >
        <Toolbar
          className={toolbarClass}
          disableGutters
        >
          <HiddenOn desktop>{renderizerIconToolbar()}</HiddenOn>
          <Grid className={classes.grow}>
            <Link to="/" className={classes.logo} id='navbar-logotipo'>
              <Logo className={classes.logo} />
            </Link>
          </Grid>
          <HiddenOn mobile>
            <DesktopMenu
              menuLinks={filteredMenuLinks}
              isLoggedIn={isLoggedIn}
              profile={profile}
              isAdmin={isAdmin}
              loginText={loginText}
            />
          </HiddenOn>
          <HiddenOn desktop>{(isLoggedIn && !hideMobileUserMenu) ? renderizerUserMobilMenu() : <div style={{width: '2.25rem'}}></div>}</HiddenOn>
        </Toolbar>
      </AppBar>
      <HiddenOn desktop>
        <MobileDrawer
          onCloseClick={handleDrawerClose}
          open={open}
          isLoggedIn={isLoggedIn}
          profile={profile}
          anchorPosition={'left'}
        >
          <MobileMenu
            menuLinks={filteredMenuLinks}
            isLoggedIn={isLoggedIn}
            isAdmin={isAdmin}
          />
        </MobileDrawer>
        <MobileDrawer
          onCloseClick={handleDrawerOpenUserMenu}
          open={openUserMenu}
          isLoggedIn={isLoggedIn}
          profile={profile}
          anchorPosition={'right'}
        >
          <LoginSection
            handleDrawerOpen={handleDrawerOpenUserMenu}
            isAdmin={isAdmin}
            isLoggedIn={isLoggedIn}
            open={openUserMenu}
            onCloseClick={toggleDrawerOpenUserMenu}
            profile={profile}
          />
        </MobileDrawer>
      </HiddenOn>
    </Grid>
  )
};

Header.propTypes = {
  menuLinks: PropTypes.array.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  profile: PropTypes.object,
  loginText: PropTypes.string
};

export default Header;

