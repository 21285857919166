import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import LoginSection from '../LoginSection.component';
import MenuLink from './MenuLink.component';
import Link from '../../common/Link.component';
import { Box, Divider } from '@material-ui/core';
import DvrIcon from '@material-ui/icons/Dvr';
import Text from '../../common/text.component';
import { navigationRoutes } from '../../../api/navigationRoutes.enum';

const BUTTON_LABEL = 'Ir al administrador';

const useStyles = makeStyles(theme => ({
  navMenu: {
    display: 'flex',
    alignItems: 'center'
  },
  adminButton: {
    display: 'flex',
    paddingLeft: '1rem',
    borderLeft: `1px solid ${theme.palette.grayScale.g200}`,
    height: '2.3rem',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover':{
      '& .MuiSvgIcon-root':{
        color: theme.palette.primary.main,
      },
    },
  },
  adminIcon: {
    color: theme.palette.grayScale.g400,
    marginRight: '0.8rem',
  },
  divider:{
    backgroundColor:'#000000',
    height: '1.25rem',
    margin:'auto 1rem',
  }
}));

const DesktopMenu = ({ menuLinks, isLoggedIn = false, profile, isAdmin, loginText }) => {
  const classes = useStyles();
  return(
    <List component="nav" disablePadding={true} className={classes.navMenu} dense={true}>
      {
        menuLinks.map(({ name, link, subLinks }) =>
            <MenuLink key={name} name={name} link={link} subLinks={subLinks} />
          )
      }
      {isAdmin &&
        <Link to={navigationRoutes.ADMIN}>
          <Box className={classes.adminButton}>
            <DvrIcon className={classes.adminIcon}/>
            <Text normal>{BUTTON_LABEL}</Text>
          </Box>
        </Link>
      }
      {!isLoggedIn && menuLinks.length ? <Divider className={classes.divider} orientation='vertical' variant='fullWidth' flexItem /> : null}
        <LoginSection isDesktop={true} isLoggedIn={isLoggedIn} profile={profile} loginText={loginText} />
    </List>
  );
};

DesktopMenu.propTypes = {
  menuLinks: PropTypes.array.isRequired,
  isLoggedIn: PropTypes.bool,
  profile: PropTypes.object,
};

export default DesktopMenu;
