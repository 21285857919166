import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Link from '../../common/Link.component';
import { isActiveDetailCatalogClass } from '../utils/utils';
import { useLocation } from '@reach/router';
import { MENU_BUTTON_ID } from '../../../utils/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '20rem',
    backgroundColor: theme.palette.grayScale.g0,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  activeLink: {
    display: 'inline-flex',
    '&:after': {
      content: '\'\'',
      display: 'inline-block',
      width: theme.typography.size.smaller,
      height: theme.typography.size.smaller,
      textAlign: 'center',
      marginTop: theme.spacing(2.25),
      borderRadius: 99,
    }
  }
}));

const MenuLink = ({ link, name, subLinks, state }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  const isActiveDetailClass = isActiveDetailCatalogClass(location, link, classes);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      {subLinks && subLinks.length > 0 ? (
        <>
          <ListItem button onClick={handleClick}>
            <ListItemText primary={name}/>
            {open ? <ExpandLess/> : <ExpandMore/>}
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {subLinks.map((subLink) => (
                <Link key={subLink.name} to={subLink.link} activeClassName={classes.activeLink} state={state}
                  className={`${isActiveDetailClass}`}>
                  <ListItem className={classes.nested}>
                    <ListItemText primary={subLink.name}/>
                  </ListItem>
                </Link>
              ))}
            </List>
          </Collapse>
        </>
      ) : (
        <Link to={link} activeClassName={classes.activeLink}
          className={`${isActiveDetailClass}`} state={state}
          id={MENU_BUTTON_ID[name]}
          >
          <ListItem>
            <ListItemText primary={name}/>
          </ListItem>
        </Link>
      )
      }
    </>
  );
};

MenuLink.propTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  subLinks: PropTypes.array,
};

export default MenuLink;
