export const scrollTo = (rect, time) => {
  setTimeout(() => {
    window.scrollBy({
      left: 0,
      top: rect.top - rect.height * 2,
      behaviour: 'smooth',
    });
  }, time);
};

// Función que se usa para filtrar los links del header y footer del layout de carmuv
// Ver gatsby-config para agregar roles que no tengan acceso segun link
export const filterLinks = (arr, isLoggedIn, role) => {
  return arr.filter(
    (item) =>
      (
        !item.onlyLoggedIn
        || (item.onlyLoggedIn && isLoggedIn)
      )
      && (item?.rolesNotAllowed ? !item.rolesNotAllowed.some((e) => e === role) : true)
  );
};
