import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import MenuLink from './MenuLink.component';
import { makeStyles } from '@material-ui/core/styles';
import Link from '../../common/Link.component';
import { navigationRoutes } from '../../../api/navigationRoutes.enum';
import { Box, Divider } from '@material-ui/core';
import Text from '../../common/text.component';
import DvrIcon from '@material-ui/icons/Dvr';
import { LoggedOutSection } from '../LoginSection.component';

const BUTTON_LABEL = 'Ir al administrador';

const useStyles = makeStyles( theme => ({
  listSection:{
    marginTop: '1.5rem',
  },
  adminButton: {
    display: 'flex',
    marginTop: '10px',
    paddingLeft: '1rem',
    height: '2.3rem',
    overflow: 'hidden',
    justifyContent: 'flex',
    alignItems: 'center',
    color: theme.palette.grayScale.g500,
    fontSize: '1rem',
  },
  adminIcon: {
    marginRight: '0.8rem',
  },
  lineSeparator: {
    marginLeft: '1rem',
    marginRight: '1rem',
  },
}));

const MobileMenu = ({ menuLinks, isLoggedIn, isAdmin }) => {
  const LOGIN_TEXT = isLoggedIn ? null : <LoggedOutSection/>;

  const classes = useStyles();
  return(
    <>
      <List component="nav"  className={classes.listSection}>
        {
          menuLinks.map(({ name, link, subLinks }) =>
              <MenuLink key={name} name={name} link={link} subLinks={subLinks} />
            )
        }
      </List>
      <Divider className={classes.lineSeparator}/>
      { isAdmin &&
        <Link to={navigationRoutes.ADMIN}>
          <Box className={classes.adminButton}>
            <DvrIcon className={classes.adminIcon}/>
            <Text normal small>{BUTTON_LABEL}</Text>
          </Box>
        </Link>
      }
      { !isLoggedIn &&
        <Box className={classes.adminButton}>
          <Text>{LOGIN_TEXT}</Text>
        </Box>
      }
    </>
  );
};

MobileMenu.propTypes = {
  menuLinks: PropTypes.array.isRequired,
};

export default MobileMenu;
