import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Link from '../../common/Link.component';
import { useLocation } from '@reach/router';
import { isActiveDetailCatalogClass } from '../utils/utils';
import { MENU_BUTTON_ID } from '../../../utils/constants';

const size = 1.563;
const useStyles = makeStyles(theme => ({
  navItem: {
    marginBottom: 0,
    cursor: 'pointer',
  },
  subNav: {
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '3.75rem',

    '&:hover $subNavContent': {
      display: 'inline-flex'
    },

    '&:hover': {
      '&:after': {
        content: '\'\'',
        display: 'block',
        width: `${size * 2}rem`,
        height: `${size}rem`,
        background: theme.palette.grayScale.g100,
        borderTopLeftRadius: `${size * 2}rem`,
        borderTopRightRadius: `${size * 2}rem`,
        position: 'absolute',
        top: '2.813rem',
      },
    }
  },
  subNavContent: {
    display: 'none',
    position: 'fixed',
    top: '3.813rem',
    left: 0,
    width: '100%',
    justifyContent: 'center',
    background: theme.palette.grayScale.g100,
    height: '3.75rem',
    boxShadow: theme.shadow.bottom,
  },
  listItemText: {
    fontSize: '0.875rem',
  },
  activeLink: {
    paddingTop: '0.35rem',
    '&:after': {
      content: '\'\'',
      display: 'block',
      width: '0.35rem',
      height: '0.35rem',
      textAlign: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
      borderRadius: 99,
      background: theme.palette.primary.main,
    }
  }
}));

const MenuLink = ({ name, link, subLinks }) => {
  const classes = useStyles();
  const location = useLocation();
  const isActiveDetailClass = isActiveDetailCatalogClass(location, link, classes);

  const renderLinkWithSubLink = () => {
    return (
      <Grid
        aria-label="Show sub menu"
        className={classes.subNav}>
        <Link to={link} activeClassName={classes.activeLink} className={`${isActiveDetailClass}`}>
          <ListItem className={classes.navItem}>
            <ListItemText primary={name}/>
          </ListItem>
        </Link>
        <List
          className={classes.subNavContent}
          elevation={3}
        >
          {subLinks.map((subLink) => (
            <Link key={subLink.name} to={subLink.link}>
              <ListItem className={classes.navItem}>
                <ListItemText primary={subLink.name} className={classes.listItemText}/>
              </ListItem>
            </Link>
          ))
          }
        </List>
      </Grid>
    );
  };

  const renderLink = () => {
    return (
      <Link to={link}
        activeClassName={classes.activeLink}
        className={`${isActiveDetailClass}`}
        id={MENU_BUTTON_ID[name]}
      >
        <ListItem className={classes.navItem}>
          <ListItemText primary={name}/>
        </ListItem>
      </Link>
    );
  };

  return (
    <>
      {subLinks && subLinks.length > 0 ?
        renderLinkWithSubLink() :
        renderLink()
      }
    </>
  );
};

MenuLink.propTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  subLinks: PropTypes.array,
};

export default MenuLink;
